function bindMenu() {
  var currentBgId = 0
  var newBgId = 0
  var currentUrl = null
  var modalBackgrounds = [].slice.call(document.querySelectorAll('.modal-background'))

  jQuery('.link-bg').on('mouseenter', function (event) {
    var url = event.target.getAttribute('data-bg-url')

    if (url === currentUrl) {
      return
    }

    newBgId = (currentBgId + 1) % 2

    if (url) {
      modalBackgrounds[newBgId].style.backgroundImage = 'url(' + url + ')'
      modalBackgrounds[newBgId].style.opacity = 1
      modalBackgrounds[currentBgId].style.opacity = 0
    } else {
      modalBackgrounds[0].style.opacity = 0
      modalBackgrounds[1].style.opacity = 0
    }

    currentBgId = newBgId
    currentUrl = url
  })

  jQuery('.link-bg').on('mouseleave', function () {
    modalBackgrounds[0].style.opacity = 0
    modalBackgrounds[1].style.opacity = 0
    currentUrl = null
  })
}
