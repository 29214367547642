function bindClicks() {

  jQuery('[data-toggle="search"]').click(function () {
    var searchWrapper = jQuery('#search-wrapper')
    searchWrapper.toggleClass('show')

    if (searchWrapper.hasClass('show')) {
      jQuery('#search-wrapper form')
        .fadeIn(250, function () {
          searchWrapper.removeClass('search-initial-state')
          jQuery('#search-wrapper .form-control').focus()
        })
    } else {
      jQuery('#search-wrapper .form-control').blur()
      jQuery('#search-wrapper form').fadeOut(250)
    }
  })

  jQuery('.close-navigation').click(function () {
    jQuery("#navigation-modal").modal('hide')
  })

  jQuery('.contact-tab img').click(function () {
    jQuery('#aside-contact').toggleClass('show')
  })

}
