function bindAnimations() {
  var parallax = -.5
  var bgImages = jQuery('.wp-block-cover.parallax')
  var offsetTops

  setOffsetTops()
  adjustImagesPosition()

  jQuery(window).resize(setOffsetTops)
  jQuery(window).scroll(adjustImagesPosition)


  function setOffsetTops() {
    offsetTops = []

    bgImages.each(function (i, el) {
      offsetTops.push(jQuery(el).offset().top)
    })
  
  }

  function adjustImagesPosition() {
    var dy = jQuery(this).scrollTop()

    bgImages.each(function (i, el) {
      jQuery(el).css('background-position', '50% ' + (dy - offsetTops[i]) * parallax + 'px')
    })
  }
}
