function applyBgHoverZoom() {

  jQuery('.bg-hover-zoom').each(function () {
    var $this = jQuery(this)
    var style = $this.attr('style')

    $this.prepend(jQuery('<div class="bg-wrapper"></div>').attr('style', style))
    $this.attr('style', null)
  })

}
